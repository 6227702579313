
import { $gettext } from 'vue-gettext';
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { defaultVersion, Designation, useOperatingSystemStore, Version } from '@/stores/operatingSystem';
import { formatBytes, removeHTML } from '@/utils/string';
import { isEqual, OptionalKeys } from '@/utils/object';
import { PopoverElement } from '../popover/BpPopoverMenu';
import { slashIcon } from '../icon/BpIcon';
import { useFormErrors } from '@/utils/form-errors';
import { useRouter } from 'vue-router';
import { useVersionStore, VersionNumber } from '@/stores/version';
import BpToast from '../toast/BpToasts';
import clone from '@sahnee/clone';

export default defineComponent({
  name: 'bp-operating-system-version-editor',
  props: {
    modelValue: Object as PropType<Version>,
    loading: Boolean,
  },
  setup(props) {
    ///-------------------------------------------------------------------
    /// STORE
    ///-------------------------------------------------------------------
    
    const store = useOperatingSystemStore();

    ///-------------------------------------------------------------------
    /// ROUTER
    ///-------------------------------------------------------------------

    const router = useRouter();
    const isEdit = computed(() => router.currentRoute.value.name?.toString().endsWith('edit'));

    ///-------------------------------------------------------------------
    /// ERROR
    ///-------------------------------------------------------------------

    const { errors, setError } = useFormErrors<Version>();

    ///-------------------------------------------------------------------
    /// FILE
    ///-------------------------------------------------------------------

    const initialVersion: OptionalKeys<Version, '_id' | '_rev'> = {
      ...defaultVersion,
      parent_id: (router.currentRoute.value.query.parent as string) || 'root',
    };

    const version = ref<OptionalKeys<Version, '_id' | '_rev'>>({ ...clone(initialVersion), ...props.modelValue ? clone(props.modelValue) : {} });

    ///-------------------------------------------------------------------
    /// RESET
    ///-------------------------------------------------------------------

    function reset() {
      if (props.modelValue && isEqual(version.value, props.modelValue)) { return }
      version.value = { ...clone(initialVersion), ...props.modelValue ? clone(props.modelValue) : {} };
    }
    watch(() => props.modelValue, reset, { immediate: true, deep: true });

    ///-------------------------------------------------------------------
    /// SAVE
    ///-------------------------------------------------------------------

    async function save() {
      let response;
      version.value.doc_type = 'operating_system_version';
      if (version.value._id) {
        response = await store.update(version.value);
      } else {
        response = await store.create(version.value);
      }
      if (response?.success) {
        BpToast.show({
          color: 'green',
          title: version.value._id
            ? $gettext('Version successfully updated')
            : $gettext('Version successfully created'),
          content: version.value._id
            ? $gettext('The version was successfully updated.')
            : $gettext('The version was successfully created.'),
          icon: 'circle-check',
        });
        router.replace({ name: 'admin.operating-system.overview' });
      } else {
        BpToast.show({
          color: 'red',
          title: version.value._id
            ? $gettext('Failed to update version')
            : $gettext('Failed to create version'),
          content: version.value._id
            ? $gettext('The version could not be updated: %{error}', { error: response?.error })
            : $gettext('The version could not be created: %{error}', { error: response?.error }),
          icon: 'circle-xmark',
        });
      }
    }

    const saveDisabled = computed(() => errors.value.size > 0);

    ///-------------------------------------------------------------------
    /// PARENT
    ///-------------------------------------------------------------------

    const parents = ref<PopoverElement[]>([]);
    watch(() => [store.isLoading(), version.value], () => {
      if (!store.loaded || store.isLoading() || !version.value) { return }
      parents.value = store.getParents({ excludeIds: version.value._id, includeRoot: true })
        .map(parent => {
          return ({ ...parent, icon: (parent._id === 'root' ? slashIcon(['far', 'compact-disc']) : ['far', 'compact-disc']) })
        }) as PopoverElement[];
    }, { immediate: true, deep: true });

    const transformParent = (parent: Designation) => {
      const path = store.getPathById(parent._id);
      if (path.length > 0) {
        return `${store.getName(parent)} <em style="opacity: 0.5;">(${path.map(parent => store.getName(parent)).join(' > ')})</em>`;
      } else if (parent._id === 'root') {
        return `<em style="opacity: 0.5;">(${store.getName(parent)})</em>`
      }
      return store.getName(parent) || '';
    };

    ///-------------------------------------------------------------------
    /// VERSIONS
    ///-------------------------------------------------------------------

    const versionStore = useVersionStore();

    const versionNumbers = computed(() => {
      return versionStore.getVersionNumbers().map(version => ({ ...version, icon: 'v' }))
    });

    const transformVersionNumber = (versionNumber: VersionNumber) => {
      const path = versionStore.getPathById(versionNumber._id);
      const component = path
        .filter(component => component.doc_type === 'version_component')
        .map(component => versionStore.getName(component))
        .join(' ');
      const product = path
        .filter(product => product.doc_type === 'version_product')
        .map(product => versionStore.getName(product))
        .join(' ');

      if (component && product) {
        return `${component} ${versionNumber.version} <em style="opacity: 0.5;">(${product})</em>`
      } else if (component) {
        return `${component} ${versionNumber.version}`;
      } else if (product) {
        return `${versionNumber.version} <em style="opacity: 0.5;">(${product})</em>`
      }
      return versionNumber.version;
    }

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      errors,
      formatBytes,
      isEdit,
      parents,
      removeHTML,
      reset,
      save,
      saveDisabled,
      setError,
      slashIcon,
      store,
      transformParent,
      transformVersionNumber,
      version,
      versionNumbers,
      versionStore,
    }
  }
})


