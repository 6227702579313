import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_input = _resolveComponent("bp-input")!
  const _component_bp_select = _resolveComponent("bp-select")!
  const _component_bp_form_button = _resolveComponent("bp-form-button")!
  const _component_bp_card = _resolveComponent("bp-card")!

  return (_openBlock(), _createBlock(_component_bp_card, {
    class: "bp-operating-system-version-editor",
    loading: _ctx.loading,
    "fill-height": "",
    "fill-width": ""
  }, {
    header: _withCtx(() => [
      (!_ctx.isEdit)
        ? (_openBlock(), _createBlock(_component_translate, {
            key: 0,
            tag: "h2"
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createTextVNode("New version")
            ])),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_translate, {
            key: 1,
            tag: "h2",
            "translate-params": { version: _ctx.removeHTML(_ctx.store.getName(_ctx.version)) }
          }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createTextVNode("Edit version \"%{version}\"")
            ])),
            _: 1
          }, 8, ["translate-params"]))
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_bp_form_button, {
        type: "save",
        disabled: _ctx.saveDisabled,
        errors: _ctx.errors,
        action: _ctx.save
      }, null, 8, ["disabled", "errors", "action"]),
      _createVNode(_component_bp_form_button, {
        type: "reset",
        action: _ctx.reset
      }, null, 8, ["action"]),
      _createVNode(_component_bp_form_button, {
        type: "cancel",
        to: { name: 'admin.operating-system.overview' }
      })
    ]),
    default: _withCtx(() => [
      (_ctx.version)
        ? (_openBlock(), _createElementBlock("form", {
            key: 0,
            class: "bp-operating-system-version-editor__form",
            onSubmit: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createVNode(_component_bp_input, {
              label: _ctx.$gettext('Name'),
              onChangeValid: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setError('name', _ctx.$gettext('Name'), $event))),
              required: "",
              maxlength: 200,
              modelValue: _ctx.version.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.version.name) = $event))
            }, null, 8, ["label", "modelValue"]),
            (_openBlock(), _createBlock(_component_bp_select, {
              data: _ctx.parents,
              label: _ctx.$gettext('Parent designation'),
              transform: _ctx.transformParent,
              key: JSON.stringify(_ctx.parents),
              onChangeValid: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setError('parent_id', _ctx.$gettext('Parent designation'), $event))),
              required: "",
              modelValue: _ctx.version.parent_id,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.version.parent_id) = $event))
            }, null, 8, ["data", "label", "transform", "modelValue"])),
            _createVNode(_component_bp_input, {
              label: _ctx.$gettext('Alias'),
              onChangeValid: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setError('alias', _ctx.$gettext('Alias'), $event))),
              modelValue: _ctx.version.alias,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.version.alias) = $event)),
              maxlength: 200
            }, null, 8, ["label", "modelValue"])
          ], 32))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["loading"]))
}